import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, AfterContentInit, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { DatePipe, DecimalPipe, TitleCasePipe } from '@angular/common';
import { MinutesPipe } from '../../shared/pipes/minutes.pipe';
import { LanguageChangeService } from '../../shared/services/language-change.service';
import { GlobalService } from '../../shared/services/global.service';
import { FilterService } from '../services/filter.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { SpaceToListJoinPipe } from '../pipes/space-to-list-join.pipe';
import { HrsMinSec } from '../pipes/hrsMinSec.pipe';
import { ProductionServiceService } from 'src/app/producciones/productions-services/production-service.service';

@Component({
	selector: 'app-tabla-general',
	templateUrl: './tabla-general.component.html',
	styleUrls: ['./tabla-general.component.css']
})
export class TablaGeneralComponent implements OnInit, OnChanges, AfterViewInit {

	@Input() titulo_tabla;
	@Input() table_headers = [];
	@Input() recibir_capitulo = false;
	@Input() disabled_paginador = false;
	@Input() campos;
	@Input() llamados;
	@Input() datos_tabla;
	@Input() page_size = 20;
	@Input() ordenar = [];
	@Input() selectedRow: string = '';
	@Input() savPagRet = {
		datos_tabla_length: null,
		pagination_ex: null,
		array: null
	};
	@Input() categorias = '';
	@Input() scrolltopRet;;
	@Input() disabled_vertical_scroll = false;
	@Input() editFieldsBlocked = false;
	@Input() plainRows = false;
	@Input() allowOrderFields = true;
	@Input() useTrackBy: boolean = false;
	@Input() trackByProperty: string = 'id';
	@Input() footerRow: any;
	@Input() footerTotals: any;
	@Input() addPrimaryHeader: boolean = false;
	@Input() showMobileMenu: boolean = false;
	@Input() backPagination: boolean = false;
	@Input() optionsPagination: any;
	@Input() staticPaginator: boolean = false;
	@Input() checkDisabled: boolean = false;
	@Input() notChangePag: boolean = false;
	@Input() maxHeight?: number = null;
	@Input() setTotalsFront: boolean = false;
	@Output() NumberOrder = new EventEmitter();
	@Output() OnCheck = new EventEmitter();
	@Output() OnClickRow = new EventEmitter();
	@Output() OnDoubleClickRow = new EventEmitter();
	@Output() OnDoubleClickCell = new EventEmitter();
	@Output() OnContextClickRow = new EventEmitter();
	@Output() OnOrder = new EventEmitter();
	@Output() OnOrderInputChange = new EventEmitter();
	@Output() savPag = new EventEmitter();
	@Output() scrolltop = new EventEmitter();
	@Output() editarLlamado = new EventEmitter();
	@Output() OnCliktoClear = new EventEmitter();
	@Output() onBackPaginator = new EventEmitter();
	@Output() onOpenComments = new EventEmitter();
	@Output() calcTotPerPag = new EventEmitter();
	// @Output() redalerts = new EventEmitter();

	@ViewChild('tabla', { static: false }) tabla;
	@ViewChild('scrl', { static: false }) scrl;
	pager_page;
	pagedTable;
	ordenAcendente = false;
	elRef;
	colorClase: any;
	arrSavPag: any;
	guardarForm: any = {
		order_number: '',
	}

	array: any;
	pagedArray: any;
	pagination: any = {
		ex: 0,
		in: 1
	}
	pag: any;
	ind: any;
	addOrderr: any;
	actualizar: boolean = false;


	showMsgAmarillo: boolean = false;
	showMsgRojo: boolean = false;

	orden_seleccionado: any;
	scroll_top: any;
	orderedcampos: any;
	add: boolean = false;

	isMobile: boolean = false;
	isMobileMini: boolean = false;

	addOrder(ev) {
		ev.add = !ev.add
		if (!ev.add) {
			ev.add = ''
		}
	}

	addOrderNumber(ev, campo) {
		var objeto: Object = { service_id: ev.service_id };
		objeto[campo.campo] = ev[campo.campo];
		this.NumberOrder.emit(objeto)
	}

	operacion(operacion, exx, inn) {
		if (operacion == 'sumar') {
			if (inn < this.datos_tabla.length) {
				this.pagination.ex = exx + this.page_size;
				this.pagination.in = inn + this.page_size;
				this.array += this.page_size;
				this.pagedArray = this.datos_tabla.slice(this.pagination.ex, this.array);
				if (this.setTotalsFront) this.calcTotPerPag.emit(this.pagedArray);
				this.savePagination();
			}
			if (inn > this.datos_tabla.length) {
				this.array = this.datos_tabla.length;
				this.savePagination();
			}
		}
		if (operacion == 'restar') {
			if (exx > 0) {
				this.pagination.ex = exx - this.page_size;
				this.pagination.in = inn - this.page_size;
				this.array -= this.page_size;
				this.pagedArray = this.datos_tabla.slice(this.pagination.ex, this.array);
				if (this.setTotalsFront) this.calcTotPerPag.emit(this.pagedArray);
				this.savePagination();
			}
		}

		this.setValidData();
	}
	finalInd(arr) {
		if (arr > this.datos_tabla.length - 1) {
			// this.savePagination();
			return this.datos_tabla.length;
		} else {
			// this.savePagination();
			return this.array;
		}
	}

	skip(pagina) {
		if (pagina == 'final') {
			var pag = (this.datos_tabla.length) / this.page_size;
			var yt = String(pag).split('.');
			var rr = Number(yt[0]) * this.page_size;

			// this.pagination.ex = (Math.ceil(pag) * this.page_size) - this.page_size;
			// this.array = this.datos_tabla.length;

			if (yt.length > 1) {
				this.pagination.ex = Number(yt[0]) * this.page_size;
				this.array = (Number(yt[0]) + 1) * this.page_size;
			} else {
				// Arregla cuando resto division es 0 (mod = 0)
				this.pagination.ex = (Number(yt[0]) - 1) * this.page_size;
				this.array = (Number(yt[0])) * this.page_size;
			}

			this.pagedArray = this.datos_tabla.slice(this.pagination.ex, this.datos_tabla.length);
			if (this.setTotalsFront) this.calcTotPerPag.emit(this.pagedArray);
			this.savePagination();
		} else if (pagina == 'inicial') {
			this.pagination.ex = 0;
			this.pagination.in = 1;
			this.array = this.page_size;
			this.pagedArray = this.datos_tabla.slice(0, this.array);
			if (this.setTotalsFront) this.calcTotPerPag.emit(this.pagedArray);
			this.savePagination();
		}
		this.setValidData();
	}

	savePagination() {
		this.arrSavPag = {
			'datos_tabla_length': this.datos_tabla.length,
			'pagination_ex': this.pagination.ex,
			'array': this.array,
			'pagedArray': this.pagedArray
		}
		this.savPag.emit(this.arrSavPag);
	}

	getViewPortHeight() {
		var viewportheight;
		if (typeof window.innerWidth != 'undefined') {
			viewportheight = this.scrl.nativeElement.offsetHeight;
		} else if (typeof this.scrl.nativeElement != 'undefined' && typeof this.scrl.nativeElement.clientWidth != 'undefined' && this.scrl.nativeElement.clientWidth != 0) {
			viewportheight = this.scrl.nativeElement.clientHeight;
		} else {
			viewportheight = this.scrl.nativeElement.clientHeight;
		}
		return viewportheight;
	}

	confirmarScroll() {
		this.scrl.nativeElement.scrollTop = this.scrolltopRet;
		this.scrollDesplazamiento();
	}

	scrollDesplazamiento() {
		var currentHeight;
		if (this.scrl.nativeElement.scrollTop == 0) {
			this.scroll_top = this.scrl.nativeElement.scrollTop;
			currentHeight = this.getViewPortHeight();
			this.scrolltop.emit(this.scroll_top);
		} else {
			this.scroll_top = this.scrl.nativeElement.scrollTop;
			currentHeight = this.getViewPortHeight();
			this.scrolltop.emit(this.scroll_top);
		}
	}

	asignarClase(fila, campo) {
		var clases = [];
		if (campo.fija) {
			if (this.isMobileMini && campo.ancho > 300) {
				return
			}
			clases.push('sticky-left');
		}

		if (campo.align) {
			clases.push('align-' + campo.align)
		}

		if (campo.tipo == 'texto_fila') {
			clases.push('no-wrapp-text')
		}


		if (this.obtenerValor(fila, campo).toString().length >= 30 || (campo.ancho / 11) <= this.obtenerValor(fila, campo).toString().length) {
			clases.push('hover-texto')
		}

		this.asignarColores(fila, campo);

		if (fila.footerRow) {
			clases.push('bold')
		}

		if (campo.tipo == 'estatus') {
			clases.push(this.colorClase);
			clases.push('nohover');
		}
		return clases.join(' ')
	}

	clickRow(data) {
		this.OnClickRow.emit(data);
	}

	checkChangedata(data) {
		this.OnCheck.emit(data);
	}

	dblClickRow(data) {
		this.OnDoubleClickRow.emit(data);
	}

	editarCamposLlamado(fila, tipo) {
		if (!tipo) {
			return;
		}
		var data = { selected_item: fila, tipo: tipo }
		this.editarLlamado.emit(data);
	}

	doubleClickCell(fila, tipo) {
		if (!tipo) {
			return;
		}
		let data = { selected_item: fila, tipo: tipo }
		this.OnDoubleClickCell.emit(data);
	}

	/**
	 * Emite eveento al usar el boton borrar
	 * @param event referencia el evento click
	 * @param fila a la que hace referecia
	 * @param tipo de campo de la celda
	 */
	clickOnClear(event, fila, tipo) {
		event.stopPropagation()

		if (!tipo) {
			return;
		}
		let data = { selected_item: fila, tipo: tipo }
		this.OnCliktoClear.emit(data);
	}

	contextClickRow(event, data) {
		this.OnContextClickRow.emit({ $event: event, data: data });
	}
	/**
	 * Emite evento para ordenar la tabla
	 * @param campo del header de la tabla a ordenar.
	 */
	ordenarTabla(campo) {
		if (campo.tipo == 'checkbox') {
			return;
		}

		// Resetea orden en los otros campos.
		this.campos = this.campos.map(x => {
			if (campo.campo != x.campo) {
				x.orden = null;
			}
			return x

		})

		// El 1 representa ordenamiento normal, el 2 DESC
		campo.orden = (campo.orden == 1) ? 2 : 1;
		// Algunos campos se ordenan referenciando a otro. Esto se define en el archivo de la tabla.	
		let campo_order = (campo.campo_order ? campo.campo_order : campo.campo)


		if (this.ordenar.indexOf(campo_order) == -1) {
			// Si el campo no existe dentro del array de ordenar.
			this.ordenar.unshift(campo_order);	// Lo agrega al principio
			this.ordenar.pop() // Elimina ultimo elemento del array.

		}

		if (this.ordenar.length == 0) {
			// Si ordenar no tiene nada.
			this.ordenar.unshift(campo_order);
		}

		let ordenar = this.ordenar.map(x => campo.orden == 2 ? (x + ' DESC') : x).join(',')
		this.OnOrder.emit(ordenar);
		this.ordenAcendente = !this.ordenAcendente;
	}

	obtenerValor(fila, campo) {
		switch (campo.tipo) {
			case "traduccion_back":
				return fila[campo.campo + '_' + this._languageChangeService.getLenguage()] || '--'

			case "estatus":
				return fila[campo.campo + '_' + this._languageChangeService.getLenguage()] || '--'

			case "fecha":
				return this.dP.transform(fila[campo.campo], 'MMM d, y') || '--'

			case "minutos":
				return this.mP.transform(fila[campo.campo]) || '--'

			case "porcentaje":
				return fila[campo.campo] + '%' || '--'

			case "currency":
				let fieldData; 
				if (campo.simbolo) {
					if (fila[campo.simbolo] == 3) 
						fieldData = fila[campo.campo] ? ('€' + this._deP.transform(fila[campo.campo])) : '--'
					else 
						fieldData = fila[campo.campo] ? ('$' + this._deP.transform(fila[campo.campo])) : '--'
				}
				else
					fieldData = fila[campo.campo] ? ('$' + this._deP.transform(fila[campo.campo])) : '--'
				return fieldData

			case "cap_esc":
				return `${fila[campo.campo]} / ${fila[campo.concat_campo]}` || '--'

			case "loc_est":
				return `${fila[campo.campo]} - ${fila[campo.concat_campo]}` || '--'

			case "loc_dia_int":
				return `${fila[campo.campo]} <br>
							${fila[campo.concat_campo_1]} <br>
							${fila[campo.concat_campo_2]}` || '--'

			case "character_report":
				return fila[campo.campo][0].concat_characters ? this._spaceListPipe.transform(fila[campo.campo][0].concat_characters) : '--';

			case "events":
				var z = [];
				for (let x of fila[campo.campo]) {
					z.push(x.event_esp)
				}
				return z.join(', ');

			case "hrsMinSec":
				return this.hmsP.transform(fila[campo.campo]) || '--:--:--';

			default:
				return fila[campo.campo] || '--'
		}
	}

	obtenerProyectos(fila, campo) {
		var x = [];
		for (let y of fila[campo.campo]) {
			const dates = `${y.init_date} ${(y.contract_end_date ? "- " + y.contract_end_date : '')})`
			x.push(`${y.production_name} (${y.character_name}) ${y.contract_end_date ? "(" + dates + ")" : ''}`);
		}
		if (fila[campo.campo].length > 0) {
			return x.join(', ');
		} else {
			return '--';
		}
	}

	asignarColores(fila, campo) {
		if (fila.scene_status_id == 1) {
			this.colorClase = 'bg-st-desarrollo';
		} else if (fila.scene_status_id == 2) {
			this.colorClase = 'bg-st-privado';
		} else if (fila.scene_status_id == 3) {
			this.colorClase = 'bg-st-noasignadas';
		} else if (fila.scene_status_id == 4) {
			this.colorClase = 'bg-st-asignadas';
		} else if (fila.scene_status_id == 5) {
			this.colorClase = 'bg-st-producido';
		} else if (fila.scene_status_id == 6) {
			this.colorClase = 'bg-st-producido-asignado';
		} else if (fila.scene_status_id == 7) {
			this.colorClase = 'bg-st-retoma';
		} else if (fila.scene_status_id == 8) {
			this.colorClase = 'bg-st-cancelado';
		} else if (fila.scene_status_id == 9) {
			this.colorClase = 'bg-st-pararevision';
		}
	}

	asignarLetras(dato, tipo) {
		switch (tipo) {
			case 1:
				if (dato == 1) {
					return 'F';
				}
			case 2:
				if (dato == 1) {
					return 'P';
				}
			case 3:
				if (dato == 1) {
					return 'T';
				}
			case 4:
				if (dato == 1) {
					return 'H';
				}
			case 5:
				if (dato != 0) {
					return 'X';
				}
			case 6:
				if (dato != 0) {
					return 'R';
				}
			default:
				return
		}
	}

	mostrarMensaje(tipo) {
		if (tipo == 'amarilla') {
			this.showMsgAmarillo = !this.showMsgAmarillo;
		} else if (tipo == 'roja') {
			this.showMsgRojo = !this.showMsgRojo;
		}
	}

	ordenarIndiceTabla(event, ordenar) {

		event.stopPropagation();

		let numero_orden = Number(this.orden_seleccionado);

		if (ordenar) {
			ordenar.order = ordenar.order > numero_orden ? ordenar.order + 1 : ordenar.order - 1;
		}

		this.datos_tabla = this.datos_tabla
			.sort((a, b) => parseInt(a.order) - parseInt(b.order))
			.map((x, i) => {
				x.order = i + 1;
				return x;
			});

		this.pagedArray = this.datos_tabla.slice(0, this.array);
		if (this.setTotalsFront) this.calcTotPerPag.emit(this.pagedArray);
		this.OnOrderInputChange.emit(this.datos_tabla);
		this.orden_seleccionado = null;
	}

	seleccionarTodas(event, campo) {
		event.stopPropagation();
		if (campo.checkboxNotForAll) {
			this.datos_tabla = this.datos_tabla.map(x => {
				if (x[campo.campoValidateCheckbox]) {
					x.checked = event.target.checked;
				}
				return x;
			})
		} else {
			this.datos_tabla = this.datos_tabla.map(x => {
				x.checked = event.target.checked;
				return x;
			})
		}
	}

	obtenerSeleccionados(campo) {
		let seleccionados = this.datos_tabla.filter(x => x.checked).length
		if (campo.checkboxNotForAll) {
			let maxNumberSelected = this.datos_tabla.filter(x => x[campo.campoValidateCheckbox]).length
			return seleccionados > 0 && seleccionados < maxNumberSelected;
		} else {
			return seleccionados > 0 && seleccionados < this.datos_tabla.length
		}
	}

	public obtenerTabla() {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve(this.tabla.nativeElement);
			}, 2000)
		})

	}

	setTableConfiguration() {
		this.orderedcampos = this._fS.filterByArgument(this.campos, 'visible', true); // si campo es visible = false no se muestra.	

	}

	setValidData() {
		for (const fila of this.pagedArray) {
			fila.valid_data = {};
			fila.valid_classes = {};
			for (const campo of this.campos) {
				fila.valid_data[campo.campo] = this.obtenerValor(fila, campo);
				fila.valid_classes[campo.campo] = this.asignarClase(fila, campo)
			}
		}
	}

	trackBy(index, item) {
		return item[this.trackByProperty];
	}

	subscribeChangesViewport() {
		this.breakpointObserver.observe([('(max-width: 1280px)')]).subscribe(
			data => {
				this.isMobile = data.matches;
			}
		)
		this.breakpointObserver.observe([('(max-width: 700px)')]).subscribe(
			data => {
				this.isMobileMini = data.matches;
			}
		)
	}

	setOrderField() {
		if (!this.ordenar || !this.ordenar.length || !this.ordenar[0]) return;
		let property = this.ordenar[0].trim();
		let sortingType = "";

		if (property) {
			property = property.replace(" DESC", "");
			sortingType = "DESC";
			let field = this.orderedcampos.find(field => field.campo == property);

			if (!field) return;
			field.orden = sortingType == 'DESC' ? 1 : 2;
		}
	}

	sendBackPaginator(event: string) {
		let offset = 0;
		if (event == 'next' || event == 'previous') {
			offset = event == 'next' ? this.optionsPagination.offset + 1 : this.optionsPagination.offset - 1;
		}
		if (event == 'last') {
			offset = Math.ceil(this.optionsPagination.total / this.page_size) - 1;
		}
		this.onBackPaginator.emit(offset);
	}

	setBackPaginator() {
		if (!this.backPagination) return;
		let endOffset = this.page_size * (this.optionsPagination.offset + 1);
		this.optionsPagination.start = this.page_size * this.optionsPagination.offset + 1;
		this.optionsPagination.end = this.optionsPagination.total > endOffset ? endOffset : this.optionsPagination.total;
	}

	openComments(item) {
		this.onOpenComments.emit(item);
	}

	constructor(
		public _fS: FilterService,
		public _pS: ProductionServiceService,
		public _gS: GlobalService,
		private dP: DatePipe,
		private mP: MinutesPipe,
		private _deP: DecimalPipe,
		private hmsP: HrsMinSec,
		private _languageChangeService: LanguageChangeService,
		private breakpointObserver: BreakpointObserver,
		private _spaceListPipe: SpaceToListJoinPipe,
		elRef: ElementRef) {
		this.elRef = elRef;
	}

	ngOnInit() {
		this.ordenar = this.ordenar ? this.ordenar : [this.campos[0].campo || null, this.campos[1].campo || null];
		this.setTableConfiguration();
		this.subscribeChangesViewport();
		if (this.savPagRet && this.savPagRet.datos_tabla_length == undefined) {
			this.array = this.page_size;
			this.pagedArray = this.datos_tabla.slice(0, this.array);
			if (this.setTotalsFront) this.calcTotPerPag.emit(this.pagedArray);
		} else {
			this.pagination.ex = this.savPagRet.pagination_ex;
			this.array = this.savPagRet.array;
			this.pagedArray = this.datos_tabla.slice(this.pagination.ex, this.array);
			if (this.setTotalsFront) this.calcTotPerPag.emit(this.pagedArray);
			if (this.pagedArray.length == 0) {
				this.skip('inicial');
			}
		}
		this.setValidData();
	}

	ngAfterViewInit() {
		this.confirmarScroll();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.datos_tabla) {
			if (!changes.datos_tabla.firstChange) {
				this.datos_tabla = changes.datos_tabla.currentValue;
				this.pagedArray = this.datos_tabla.slice(this.pagination.ex, this.array);
				if (this.setTotalsFront) this.calcTotPerPag.emit(this.pagedArray);
				if ((this.pagedArray.length && !this.notChangePag) || (this.datos_tabla.length && !this.notChangePag)) {
					this.skip('inicial');
				}
				this.setValidData();
			}
			this.setBackPaginator();
		}
		if (changes.campos) {
			this.setTableConfiguration();
		}
		if (changes.ordenar) {
			this.setOrderField();
		}
	}

}
